import { SurveyDesignSample } from 'src/app/surveys/views/new-survey-design/state/newSurveyDesignSlice';
import { ProjectDefinition } from './project';
import { CMSHabitatAssayTypeWithoutImage } from '../cms/habitatAssayType';

export enum HabitatInsightsMetricType {
    UNKNOWN_METRIC_TYPE = 'Unknown_Metric_Type',
    TREE_CONNECTIVITY = 'Tree_Connectivity',
    GRASS_CONNECTIVITY = 'Grass_Connectivity',
    SHRUB_CONNECTIVITY = 'Scrub_Connectivity',
    ALL_CONNECTIVITY = 'All_Connectivity',
    VEGETATION_INDEX = 'Vegetation_Index',
    VEGETATION_CONDITION = 'Vegetation_Condition',
    LAND_COVER = 'Land_Cover',
    PRIMARY_PRODUCTIVITY = 'Primary_Productivity',
    HABITAT_CONNECTIVITY = 'Habitat_Connectivity',
    BIODIVERSITY_INTACTNESS_INDEX = 'BiodiversityIntactnessIndex',
}

export const habitatInsightsMetricTypeLabel: Record<HabitatInsightsMetricType, string> = {
    [HabitatInsightsMetricType.UNKNOWN_METRIC_TYPE]: 'Unknown Metric Type',
    [HabitatInsightsMetricType.TREE_CONNECTIVITY]: 'Tree Connectivity',
    [HabitatInsightsMetricType.GRASS_CONNECTIVITY]: 'Grass Connectivity',
    [HabitatInsightsMetricType.SHRUB_CONNECTIVITY]: 'Shrub Connectivity',
    [HabitatInsightsMetricType.ALL_CONNECTIVITY]: 'All Connectivity',
    [HabitatInsightsMetricType.VEGETATION_INDEX]: 'Vegetation Index',
    [HabitatInsightsMetricType.VEGETATION_CONDITION]: 'Vegetation Condition',
    [HabitatInsightsMetricType.LAND_COVER]: 'Land Cover',
    [HabitatInsightsMetricType.PRIMARY_PRODUCTIVITY]: 'Vegetation Health',
    [HabitatInsightsMetricType.HABITAT_CONNECTIVITY]: 'Habitat Connectivity',
    [HabitatInsightsMetricType.BIODIVERSITY_INTACTNESS_INDEX]: 'Biodiversity Intactness Index',
};

export enum HabitatInsightsMetricGroup {
    CONNECTIVITY = 'connectivity',
    LAND_COVER = 'land_cover',
    PRIMARY_PRODUCTIVITY = 'primary_productivity',
}

export const habitatInsightsTimePeriodMetrics = [
    HabitatInsightsMetricType.LAND_COVER,
    HabitatInsightsMetricType.ALL_CONNECTIVITY,
    HabitatInsightsMetricType.GRASS_CONNECTIVITY,
    HabitatInsightsMetricType.TREE_CONNECTIVITY,
    HabitatInsightsMetricType.SHRUB_CONNECTIVITY,
];

export const habitatInsightsMetricTypeGroupMap: Record<HabitatInsightsMetricGroup, HabitatInsightsMetricType[]> = {
    [HabitatInsightsMetricGroup.CONNECTIVITY]: [
        HabitatInsightsMetricType.TREE_CONNECTIVITY,
        HabitatInsightsMetricType.GRASS_CONNECTIVITY,
        HabitatInsightsMetricType.SHRUB_CONNECTIVITY,
        HabitatInsightsMetricType.ALL_CONNECTIVITY,
    ],
    [HabitatInsightsMetricGroup.LAND_COVER]: [
        HabitatInsightsMetricType.LAND_COVER,
        HabitatInsightsMetricType.VEGETATION_CONDITION,
        HabitatInsightsMetricType.VEGETATION_INDEX,
    ],
    [HabitatInsightsMetricGroup.PRIMARY_PRODUCTIVITY]: [HabitatInsightsMetricType.PRIMARY_PRODUCTIVITY],
};

export enum HabitatInsightsProcessingStatus {
    UPLOADED = 'Uploaded',
    REQUESTED = 'Requested',
    PROCESSING = 'Processing',
    READY = 'Ready',
    ERROR = 'Error',
}

export type HabitatInsightsTileLayer = {
    year: string;
    url: string;
    boundaryUrl: string;
    status: HabitatInsightsProcessingStatus;
};

export type HabitatInsightsMetric = {
    metric: HabitatInsightsMetricType;
    layers: HabitatInsightsTileLayer[];
};

export type Site = {
    type: string;
    uploadedAt: string;
    uploadedBy: string;
    properties: {
        name: string | null;
        metrics: HabitatInsightsMetric[] | null;
        area: number;
        status: HabitatInsightsProcessingStatus;
        statusMessage: string;
    };
    geometry: {
        type: string;
        coordinates: [number, number][][];
    };
};

export type SurveyDesign = Omit<ProjectDefinition, 'habitatAssay' | 'survey' | 'subscription'> & {
    habitatAssayTypes: CMSHabitatAssayTypeWithoutImage[];
    surveyPublishDate: string;
    projectOutline: string;
    surveySamples: SurveyDesignSample[];
    subscription: {
        name: string;
        label: string;
    } | null;
    sites: Site[] | null;
};
