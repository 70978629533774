import React, { ReactNode, useEffect, useReducer } from 'react';
import classNames from 'classnames';

import TableBody from './components/main/components/table-body/TableBody';
import TableHeader from './components/main/components/table-header/TableHeader';
import TablePagination from './components/table-pagination/TablePagination';
import { defaultDataTableState, tableReducer } from './dataTableReducer';

import styles from './DataTable.module.scss';

import { type AllTableActions, type DataTableState, type DataTableColumns, type TableRows, TableRowData } from './dataTableTypes';
import Table from '../table/Table';

type DownloadHandler = (props: Pick<TableContextType, 'rows' | 'columns' | 'downloadFileName'>) => Promise<void>;

export type TableContextType = DataTableState & {
    columns: DataTableColumns;
    emptyStateMessage?: ReactNode;
    downloadFileName?: string;
    rows: TableRows;
    totalRowsForPagination: number;
    dispatch: React.Dispatch<AllTableActions>;
    downloadHandler?: DownloadHandler;
    onRowClick?: (rowData: TableRowData) => void;
    onPageChange?: (pageNumber: number) => void;
};

export const TableContext = React.createContext(null as TableContextType | null);

export type TableProps = {
    state?: Partial<DataTableState>;
    emptyStateMessage?: ReactNode;
    columns: DataTableColumns;
    className?: string;
    downloadFileName?: string;
    downloadHandler?: DownloadHandler;
    onStateChange?: (newState: DataTableState) => void;
    onRowSelect?: (rowData: TableRowData) => void;
    onPageChange?: (pageNumber: number) => void;
    totalRowsForPagination?: number;
};
const DataTable = (props: TableProps) => {
    const initialState = {
        ...defaultDataTableState,
        ...(props.state || {}),
    };

    const [tableState, dispatch] = useReducer(tableReducer, initialState);

    /*
        The useReducer used above does not update the tableState when the parent component updates the state.
        To fix this, we need to check if state property has changed by the parent and reset it if necessary.
    */
    useEffect(() => {
        dispatch({
            type: 'restore_defaults',
            payload: initialState,
        });
    }, [props.state]);

    // Commented the below code to fix datatable render issue in Sample Reception
    // TODO: Replace the below commented code to Prevent rerendering on first render
    /*
        The useReducer used above does not update the tableState when the parent component updates the state.
        To fix this, we need to check if state property has changed by the parent and reset it if necessary.
    */
    // const firstRenderRef = useRef(true)
    // const shouldResetStateRef = useRef(true);
    // useEffect(() => {
    //     if (shouldResetStateRef.current && !firstRenderRef.current) {
    //         dispatch({
    //             type: 'restore_defaults',
    //             payload: initialState,
    //         });
    //     }

    //     /*
    //         Here we reset shouldResetStateRef to the initial value (true), so that the
    //         dispatch above gets executed when the state from the parent changes
    //     */
    //     shouldResetStateRef.current = true;
    // }, [props.state]);

    // useEffect(() => {
    //     if (firstRenderRef.current) {
    //         props.onStateChange?.(tableState);

    //         /*
    //             The onStateChange call above will update a state in the parent component which will trigger a rerender.
    //             At this point, we do not want to reset the tableState in the useEffect above
    //             So to prevent it, we set shouldResetStateRef to false
    //         */
    //         shouldResetStateRef.current = false;
    //     }
    //     firstRenderRef.current = false;
    // }, [tableState]);

    const wrapperClasses = classNames(styles.wrapper, props.className);
    const tableClasses = classNames(styles.table);

    /*
        The below code is to make pagination work with the API.
        The API will return the total number of rows, and the current page number.
        For rowsPerPage value of 3, and current page number of 2, the data should be positioned as follows:
        [
            [emptyRow, emptyRow, emptyRow],
            [row1, row2, row3],
            [emptyRow, emptyRow, emptyRow],
        ]
    */
    const emptyRow = Array.from({ length: props.columns.length }, () => '');
    const currentPage = tableState.currentPageNumber;

    let positionedData = tableState.data;
    if (props.totalRowsForPagination && tableState.data.length !== props.totalRowsForPagination) {
        const fillEmptyRows = Array.from(
            { length: props.totalRowsForPagination - ((currentPage - 1) * tableState.rowsPerPage + tableState.data.length) },
            () => emptyRow
        );
        positionedData = [
            ...Array.from({ length: (currentPage - 1) * tableState.rowsPerPage }, () => emptyRow),
            ...tableState.data,
            ...fillEmptyRows,
        ].filter(Boolean);
    }

    return (
        <TableContext.Provider
            value={{
                ...tableState,
                dispatch,
                columns: props.columns,
                emptyStateMessage: props.emptyStateMessage,
                rows: positionedData.map((row, index) => ({
                    rowId: index,
                    cells: row,
                })),
                downloadFileName: props.downloadFileName,
                totalRowsForPagination: props.totalRowsForPagination || tableState.data.length,
                downloadHandler: props.downloadHandler,
                onRowClick: (rowData: TableRowData) => props.onRowSelect?.(rowData),
                onPageChange: props.onPageChange,
            }}
        >
            <div className={wrapperClasses} data-testid='datatable'>
                <div className={styles.tableContainer}>
                    <Table className={tableClasses} stickyHeader={true}>
                        <TableHeader />
                        <TableBody />
                    </Table>
                </div>
                <TablePagination />
            </div>
        </TableContext.Provider>
    );
};

export default DataTable;
