import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from 'src/routes/ProtectedRoute';
import PageNotFound from 'src/shared/components/page-not-found/PageNotFound';
import { getInsightRoutesAvailability } from 'src/routes/state/routesSelector';
import { useAppSelector } from 'src/store';
import { IndividualRouteSetting, InsightViews } from 'src/routes/routesConfig';
import { Resources } from 'src/shared/types';
import useFeature from 'src/shared/hooks/useFeature';
import CommunityComparison from './views/community-comparison/CommunityComparison';
import SpeciesTrends from './views/species-trends/SpeciesTrends';
import StatisticalComparison from './views/statistical-comparison/StatisticalComparison';
import TaxonomicComposition from './views/taxonomic-composition/TaxonomicComposition';
import SampleComparison from './views/sample-comparison/SampleComparison';
import HabitatTrends from './views/habitat-trends/HabitatTrends';
import FreemiumInsights from './views/freemium-insights/FreemiumInsights';

export const insightRouteSettings: { [key in InsightViews]: IndividualRouteSetting } = {
    speciesTrends: {
        name: 'Species Trends',
        path: '/species-trends',
        handler: <SpeciesTrends />,
        resource: Resources.SPECIES_TRENDS,
    },
    habitatTrends: {
        name: 'Habitat Trends',
        path: '/habitat-trends',
        handler: <HabitatTrends />,
        resource: Resources.HABITAT_TRENDS,
    },
    sampleComparison: {
        name: 'Sample Comparison',
        path: '/sample-comparison',
        handler: <SampleComparison />,
        resource: Resources.SAMPLE_COMPARISON,
    },
    statisticalComparison: {
        name: 'Statistical Comparison',
        path: '/statistical-comparison',
        handler: <StatisticalComparison />,
        resource: Resources.STATISTICAL_COMPARISON,
    },
    taxonomicComposition: {
        name: 'Taxonomic Composition',
        path: '/taxonomic-composition',
        handler: <TaxonomicComposition />,
        resource: Resources.TAXONOMIC_COMPARISON,
    },
    communityComparison: {
        name: 'Community Comparison',
        path: '/community-comparison',
        handler: <CommunityComparison />,
        resource: Resources.COMMUNITY_COMPARISON,
    },
};

const Insights = () => {
    const routesConfig = useAppSelector(getInsightRoutesAvailability);

    const { hasFeature } = useFeature();
    const isEcostatsAvailable = hasFeature({
        resource: Resources.ECO_STATS,
    });

    if (!isEcostatsAvailable) {
        return <FreemiumInsights />;
    }
    return (
        <Routes>
            {Object.keys(routesConfig).map(routeKey => {
                const isAvailable = routesConfig[routeKey as InsightViews];
                const routeSetting = insightRouteSettings[routeKey as InsightViews];

                return (
                    <Route
                        path={routeSetting.path}
                        element={
                            <ProtectedRoute isAllowed={isAvailable} redirectPath={'/projects'} routeSetting={routeSetting}>
                                {routeSetting.handler}
                            </ProtectedRoute>
                        }
                        key={routeSetting.name}
                    />
                );
            })}
            <Route path='/*' element={<PageNotFound />} />
        </Routes>
    );
};
export default Insights;
