import React, { useEffect, useRef, useState } from 'react';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import DvrOutlinedIcon from '@mui/icons-material/DvrOutlined';
import SampleManifestIcon from 'src/assets/svg/general/sample-manifest.svg?react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import HabitatInsightsMapIcon from '@mui/icons-material/TravelExplore';
import { MoreVert } from '@mui/icons-material';

import moment from 'moment';
import { STANDARD_DATE_FORMAT } from 'src/shared/constants/dateConstants';
import { useProjectQuery } from 'src/app/projects/views/project-list/state/api/projectListGraphSlice';
import cmsService from 'src/services/cms-service';
import {
    CMSHabitatAssayType,
    ProgressStatus,
    ProjectDefinition,
    SubscriptionType,
    Access,
    Resources,
    ProjectSampleManifestStatus,
} from 'src/shared/types';
import MapCard from 'src/shared/components/map-card/MapCard';
import { countries } from 'src/shared/data/countries';
import Chip from 'src/shared/components/chip/Chip';
import useProject from 'src/shared/hooks/useProject';
import usePermissions from 'src/shared/hooks/usePermissions';

import config from 'src/config';
import Select from 'src/shared/components/select/Select';
import authService from 'src/services/auth-service';
import { downloadBlobAsFile } from 'src/shared/helpers/downloadAsFile';
import { LinearLoader } from 'src/shared/components/loader/Loader';
import useDemoProjectAnalytics from 'src/shared/components/demo-project-banner/hooks/useDemoProjectAnalytics';
import TestTypeChip from 'src/shared/components/test-type-chip/TestTypeChip';
import useProjectAnalytics from 'src/app/projects/hooks/useProjectAnalytics';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';
import useSampleManifestAnalytics from 'src/app/samples/sample-manifest/hooks/useSampleManifestAnalytics';
import styles from './ProjectListItem.module.scss';

export type ProjectListItemProps = {
    projectData: ProjectDefinition;
};

export type FilesProps = {
    awsFileKey: string;
    fileCreationDate: string;
    name: string;
};

const ProjectListItem = (props: ProjectListItemProps) => {
    const { projectData } = props;
    const { setCurrentProjectId } = useProject();
    const { hasPermission, isCurrentUserInternal } = usePermissions();
    const navigate = useAppNavigation();
    const [shouldShowMenu, showMenu] = useState(false);
    const [isPreparingDownload, setPreparingDownload] = useState(false);
    const [isDemoProject, setIsDemoProject] = useState<boolean>(false);
    const { trackDemoProjectSelection } = useDemoProjectAnalytics();
    const hasHabitatInsightsData = Boolean(projectData.survey?.sites);
    const selectRef = useRef<HTMLDivElement>(null);
    const { trackReportDownload } = useProjectAnalytics();
    const { trackSampleManifestLandingFromProjectCard } = useSampleManifestAnalytics();

    const { currentData } = useProjectQuery({
        projectId: projectData.projectId || '',
    });

    const isBasicSubscription = projectData.subscriptionType === SubscriptionType.BASIC;

    const isSpeciesMapAvailable = hasPermission({
        to: Access.VIEW,
        projectId: projectData.projectId,
        resource: Resources.SPECIES_INSIGHTS_MAP,
    });

    const isProjectDefinitionAvailable =
        projectData.status === ProgressStatus.COMPLETED &&
        hasPermission({
            to: Access.VIEW,
            resource: Resources.PROJECT_DEFINITION,
            projectId: projectData.projectId,
        });

    const hasSurveyDesign =
        projectData.survey?.status === ProgressStatus.COMPLETED &&
        hasPermission({
            to: Access.VIEW,
            resource: Resources.SURVEY_DESIGN,
            projectId: projectData.projectId,
        });

    const hasHabitatInsightsPageAccess = hasPermission({
        to: Access.VIEW,
        resource: Resources.HABITAT_INSIGHTS_PAGE,
        projectId: projectData.projectId,
    });

    useEffect(() => {
        const fetchProjectDetailsfromCms = async () => {
            const { isDemo } = (await cmsService.getProjectById(projectData.projectId)) || false;
            setIsDemoProject(isDemo);
        };
        fetchProjectDetailsfromCms();
    }, []);

    const hasEDnaData = projectData.samplesCount > 0;
    const sampleManifestStatus = projectData.sampleManifestStatus || ProjectSampleManifestStatus.NOT_APPLICABLE;
    const isSamplesManifestAvailable =
        sampleManifestStatus !== ProjectSampleManifestStatus.NOT_APPLICABLE &&
        hasPermission({
            projectId: projectData.projectId,
            to: Access.VIEW,
            resource: Resources.SAMPLE_MANIFEST,
        });

    const onSelectProject = async () => {
        if (isDemoProject) {
            trackDemoProjectSelection(projectData.projectName);
        }

        if (isSpeciesMapAvailable && hasEDnaData) {
            // Open Species insights map if eDNA data or both eDNA and Habitat insights data is available
            openSpeciesInsightsMap();
        } else if (hasHabitatInsightsPageAccess && hasHabitatInsightsData) {
            // Open Habitat insights map if only Habitat insights data is available
            openHabitatInsightsMap();
        } else if (isProjectDefinitionAvailable) {
            openProjectDefinition();
        } else if (hasSurveyDesign) {
            openSurveyDesign();
        } else if (isBasicSubscription) {
            showDownloadMenu();
        }
    };

    const showDownloadMenu = () => {
        showMenu(true);

        setTimeout(() => {
            selectRef.current?.querySelector('[role="button"]')?.dispatchEvent(
                new MouseEvent('mousedown', {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                    buttons: 1,
                })
            );
        }, 100);
    };

    const openSpeciesInsightsMap = () => {
        setCurrentProjectId(projectData.projectId);
        setTimeout(() => {
            navigate.toSpeciesMap();
        }, 1);
    };

    const openHabitatInsightsMap = () => {
        setCurrentProjectId(projectData.projectId);
        setTimeout(() => {
            navigate.toHabitatMap();
        });
    };

    const openSampleManifest = () => {
        setCurrentProjectId(projectData.projectId);
        // Capture GA for sample manifest landing from project card
        trackSampleManifestLandingFromProjectCard(projectData.projectName);
        setTimeout(() => {
            navigate.toSampleManifest();
        });
    };

    const openProjectDefinition = () => {
        setCurrentProjectId(projectData.projectId);
        setTimeout(() => {
            navigate.toProjectDefinition();
        }, 1);
    };
    const openSurveyDesign = () => {
        setCurrentProjectId(projectData.projectId);
        setTimeout(() => {
            navigate.toSurveyDesignSummary();
        }, 1);
    };

    const setDateFormat = (dateStr: string) => {
        const dateObject = new Date(dateStr);
        return moment(dateObject).format(STANDARD_DATE_FORMAT);
    };
    const habitatsAssays = projectData?.habitatAssay;

    const toggleMenu = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();

        showMenu(!shouldShowMenu);
    };

    const handleFileDownload = async (awsFileKey: string) => {
        setPreparingDownload(true);
        try {
            const file = projectData.files.find(item => item.awsFileKey === awsFileKey);
            const fileUrl = `${config.apiBaseUrl}/report?fileKey=${awsFileKey}`;
            const token = {
                Authorization: `${authService.getJWTtoken()}`,
            };
            const response = await fetch(fileUrl, {
                method: 'GET',
                headers: token,
            });
            downloadBlobAsFile(await response.blob(), `${projectData?.projectName}-${file?.name}.zip`);
        } finally {
            setPreparingDownload(false);
            trackReportDownload(projectData.projectName);
        }
    };

    const menuContent = (
        <div className={styles.menuContent}>
            {hasSurveyDesign && (
                <div className={styles.menuItem} onClick={openSurveyDesign}>
                    <div className={styles.menuItemIcon}>
                        <ContentPasteSearchIcon />
                    </div>
                    <div className={styles.menuItemLabel}>View survey design</div>
                </div>
            )}
            {isProjectDefinitionAvailable && (
                <div className={styles.menuItem} onClick={openProjectDefinition}>
                    <div className={styles.menuItemIcon}>
                        <FeedOutlinedIcon />
                    </div>
                    <div className={styles.menuItemLabel}>View project definition</div>
                </div>
            )}
            {isSpeciesMapAvailable && hasEDnaData && (
                <div className={styles.menuItem} onClick={openSpeciesInsightsMap}>
                    <div className={styles.menuItemIcon}>
                        <DvrOutlinedIcon />
                    </div>
                    <div className={styles.menuItemLabel}>
                        {isBasicSubscription ? 'Open species map (starter)' : 'Open species insights'}
                    </div>
                </div>
            )}
            {hasHabitatInsightsPageAccess && hasHabitatInsightsData && (
                <div className={styles.menuItem} onClick={openHabitatInsightsMap}>
                    <div className={styles.menuItemIcon}>
                        <HabitatInsightsMapIcon />
                    </div>
                    <div className={styles.menuItemLabel}>Open habitat insights</div>
                </div>
            )}
            {isSamplesManifestAvailable && (
                <div className={styles.menuItem} onClick={openSampleManifest}>
                    <div className={styles.menuItemIcon}>
                        <SampleManifestIcon className='fill-black' />
                    </div>
                    <div className={styles.menuItemLabel}>Sample Manifest</div>
                </div>
            )}
            {projectData?.files?.length > 0 && (
                <>
                    {!isPreparingDownload && <div className={styles.separator}></div>}
                    {isPreparingDownload && <LinearLoader />}
                    <div className={styles.menuItem}>
                        <div className={styles.menuItemIcon}>
                            <FileDownloadOutlinedIcon />
                        </div>
                        <div className={styles.menuItemLabel} ref={selectRef}>
                            <Select
                                width={175}
                                placeholder='Download Report'
                                options={projectData?.files.map(item => ({
                                    label: item.name,
                                    value: item.awsFileKey,
                                }))}
                                onChange={handleFileDownload}
                                selectedValue=''
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );

    return (
        <div
            className={styles.pojectListItem}
            key={projectData.projectId}
            data-testid={`project-card-${projectData.projectId}`}
            onClick={onSelectProject}
        >
            <div className={styles.mapContainer}>
                <MapCard boundsArray={countries[projectData?.country]?.[1]} />
            </div>
            <div>
                <div className={styles.titleRow}>
                    <h3 className={styles.cardTitle}>{projectData.projectName}</h3>
                    <div className={styles.menu}>
                        <div className={styles.menuToggle} onClick={toggleMenu}>
                            <MoreVert />
                        </div>
                        {shouldShowMenu && (
                            <div className={styles.menuPopup} onClick={e => e.stopPropagation()}>
                                {menuContent}
                            </div>
                        )}
                    </div>
                </div>
                {isCurrentUserInternal && <div className={styles.cardSubTitle}>Project code: {projectData.projectCode}</div>}
            </div>
            <div className={styles.headerText}>
                {currentData?.project?.projectStartDate ? (
                    <span className={styles.date}>{setDateFormat(currentData?.project?.projectStartDate)}</span>
                ) : null}
                {projectData.samplesCount ? <span className={styles.sampleCount}>{projectData.samplesCount} samples</span> : null}
            </div>
            {isBasicSubscription ? (
                <div className={styles.chips}>
                    <Chip label='Starter' backgroundColor='#3EA8A6' />
                </div>
            ) : (
                <div className={styles.chips}>
                    {hasEDnaData && <Chip label='Species Insights' backgroundColor='#076769' />}
                    {projectData.survey && projectData.survey.status === ProgressStatus.COMPLETED && (
                        <Chip label='Survey design' backgroundColor='#073A69' />
                    )}
                    {hasHabitatInsightsData && <Chip label='Habitat Insights' backgroundColor='#008C6A' />}
                </div>
            )}
            {habitatsAssays.length && (
                <div className={styles.testTypesWrapper}>
                    <div>Test(s)</div>
                    <div className={styles.testTypes}>
                        {habitatsAssays.map((item: CMSHabitatAssayType, index: number) => {
                            return <TestTypeChip key={index} testTypeKey={item.habitatAssayKey} />;
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProjectListItem;
